import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class FinancialGraphService {
    async getConnects(filterData) {
        const config = {
            headers: authHeader()
        };

        const response = await axios
            .post(`${API_URL}connects`, filterData, config);

        return response.data;
    }

    async addConnect(formdata) {
        const config = {
            headers: authHeader()
        };

        const response = await axios
            .post(`${API_URL}connect/add`, formdata, config);

        return response.data;
    }

    async getServiceFee(period, profile) {
        const config = {
            headers: authHeader()
        };
        const response = await axios
            .post(`${API_URL}financial-graph/service-fee`, { period, profile }, config);

        return response.data;
    } 

    async import(data) {
        console.log('data',data);
        const config = {
          headers: authHeader()
        };
    
        config.headers['Content-Type'] = 'multipart/form-data';
    
        const response = await axios
          .post(`${API_URL}transaction/import`, data, config);
    
        return response.data;
    }

    async import_reports(data) {
        console.log('data',data);
        const config = {
          headers: authHeader()
        };
    
        config.headers['Content-Type'] = 'multipart/form-data';
    
        const response = await axios
          .post(`${API_URL}reports/import`, data, config);
    
        return response.data;
    }

    async import_contract(data) {
        console.log('data11',data);
        const config = {
          headers: authHeader()
        };
    
        config.headers['Content-Type'] = 'multipart/form-data';
    
        const response = await axios
          .post(`${API_URL}contract/import`, data, config);
    
        return response.data;
    }
}

export default new FinancialGraphService();