<template>
     <div class="nofoundPage text-center">
        <h2>401</h2>
        <h3>Oops!</h3>
        <p>Access Denied, You currently does not have access to this page.</p>
        <router-link :to="'/dashboard/profile-roster'" class="btn primary-btn">Return to home</router-link>
      </div>
</template>
<script>
   export default {}
</script>
<style>   
.nofoundPage h2 {
    font-size: 150px;
    margin: 0;
    color: #666;
    font-weight: 500;
}
.nofoundPage  p {
    font-size: 18px;
    margin: 20px 0 20px;
    color: #666;
}
.nofoundPage h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #666;
}
</style>