<template>
  <div class="side-modal--wrapper">
    <div class="side-modal--content" :class="contentClass">
      <h4 class="side-modal--heading">
        <slot name="header"></slot>
        <button class="close" type="button" @click.prevent="close()">×</button>
      </h4>
      <div class="side-modal--body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SideModal",
  props: ["contentClass"],
  beforeUnmount() {
    this.close();
  },
  methods: {
    close() {
      let vm = this;
      vm.$store.dispatch("getUserDetails");
      if (localStorage.getItem("import_steps") !== null) {
        localStorage.removeItem('import_steps');
      }
      this.$emit("close");
    },
  },
};
</script>
