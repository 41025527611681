<template>
  <!-- side popup modal -->
  <form
    method="post"
    @submit.prevent="submitHandler()"
    enctype="multipart/form-data"
  >
    <div class="form-field-group">
      <h3 class="form-title">Add Connect</h3>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center radio-fields">
          <label class="form-label" for="status">Type</label>
          <div class="radio-options">
            <ul class="d-flex align-items-center inline-radio">
              <li>
                <input
                  type="radio"
                  id="Purchased"
                  name="radio-group"
                  value="Purchased"
                  v-model.trim="connect.Type"
                />
                <label for="Purchased">Purchased</label>
              </li>
              <li>
                <input
                  type="radio"
                  id="Refund"
                  name="radio-group"
                  value="Refunded"
                  v-model.trim="connect.Type"
                />
                <label for="Refund">Refunded</label>
              </li>
              <li>
                <input
                  type="radio"
                  id="Awarded"
                  name="radio-group"
                  value="Awarded"
                  v-model.trim="connect.Type"
                />
                <label for="Awarded">Awarded</label>
              </li>
            </ul>
            <span
              v-if="submitted && v$.connect.Type.required.$invalid"
              class="error"
              >* required.</span
            >
          </div>
        </div>
      </div>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="start-date">Date</label>
          <div class="form-field">
            <div class="date-field">
              <datepicker
                id="date"
                :maxDate="$filters.dateFormat('', 'YYYY-MM-DD')"
                :enableTimePicker="false"
                autoApply
                placeholder="Date"
                v-model="connect.Date"
                :format="format"
              >
                <template #input-icon>
                  <span class="lnr lnr-calendar-full"></span>
                </template>
              </datepicker>
            </div>
            <span
              v-if="submitted && v$.connect.Date.required.$invalid"
              class="error"
              >* required.</span
            >
          </div>
        </div>
      </div>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="weekly-salary"
            >Number of connects</label
          >
          <div class="form-field">
            <input
              class="form-control"
              placeholder="Connect Purchased"
              type="text"
              v-model.trim="connect.ConnectPurched"
              v-on:keypress="NumbersOnly($event)"
              onpaste="return false;"
            />
            <span
              v-if="submitted && v$.connect.ConnectPurched.required.$invalid"
              class="error"
              >* required.</span
            >
          </div>
        </div>
      </div>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="hourly-rate">Fee</label>
          <div class="form-field">
            <div class="hourly-rate-field">
              <span class="field-icon">$</span>
              <input
                class="form-control"
                placeholder="Fee"
                type="text"
                v-model.trim="connect.Fee"
                onpaste="return false;"
                v-on:keypress="HourlyRate($event)"
              />
            </div>
            <span
              v-if="submitted && v$.connect.Fee.required.$invalid"
              class="error"
              >* required.</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="form-controls">
      <div class="button-row d-flex justify-content-end">
        <button
          class="ml-auto secondary-btn"
          title="Cancel"
          type="button"
          @click.prevent="$emit('close')"
        >
          CANCEL
        </button>
        <button
          class="ml-3 js-btn-next primary-btn"
          type="submit"
          @click.prevent="submitHandler()"
        >
          Add
        </button>
      </div>
    </div>
  </form>
</template>
<script>

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import common from "../mixins/common";
import financialGraphService from "../services/financialGraph.service";
import NumbersOnly from "../mixins/numbersOnly";

export default {
  setup() {
    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day < 10 ? "0" + day : day}/${
        month < 10 ? "0" + month : month
      }/${year}`;
    };

    return {
      format,
      v$: useVuelidate(),
    };
  },
  mixins: [NumbersOnly, common],
  components: {
    Datepicker,
  },
  data: () => ({
    submitted: false,
    connect: {
      //Id: "",
      Date: new Date(),
      ConnectPurched: "",
      Fee: "",
      Type: "Purchased",
    },
  }),
  validations() {
    return {
      connect: {
        Date: {
          required,
        },
        ConnectPurched: {
          required,
        },
        Type: {
          required,
        },
        Fee: {
          required: requiredIf(function () {
            if (["Purchased", "Refund"].includes(this.connect.Type)) {
              return true;
            }

            return true;
          }),
        },
      },
    };
  },
  methods: {
    async submitHandler() {
      let vm = this;
      await vm.$store.dispatch("getUserDetails");
      vm.submitted = true;
      vm.v$.$touch();

      const isValidate = await vm.v$.$validate();

      if (!isValidate) return;

      try {
        let formData = vm.connect;
        vm.$store.commit("loadingStatus", true);
        await vm.$store.dispatch("getUserDetails");
        const response = await financialGraphService.addConnect(formData);
        vm.toastMessage(response.message, "success");
        vm.$emit("refreshList");
        vm.$emit("close");
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
  },
};
</script>
